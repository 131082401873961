<template>
  <div class="px-2 border-bottom bg-hover" v-bind:style="{ 'background-color': 'rgba(255, 245, 157, ' + backgroundAlpha + ')' }">
    <b-row no-gutters>
      <b-col cols="2" class="py-1">
        <div class="d-flex flex-row">
          <img class="img-coin" :src="coin.logoUrl">
          <div>
            <div>{{ symbol }} <b-icon-pin-angle-fill v-if="coin.isPinned" /></div>
            <a :href="'https://www.coingecko.com/en/coins/' + coin.coingeckoId" target="_blank" class="text-secondary">
              {{ coin.name }}
            </a>
          </div>
        </div>
      </b-col>
      <div class="col-9">
        <div v-if="shouldShowPriceArbitrageRow" class="d-flex flex-row">
          <div style="flex: 0 0 11.1111%" class="py-1">
            <div><small class="text-secondary">Price</small></div>
            <div class="mb-1">{{ displayArbitragePercent }}%</div>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="arbBuyAt"
              :coingecko-id="coin.coingeckoId"
              :item="arbBuyAt"
              :type="arbBuyAt.type"
              :price-type="arbitrageSettings.priceType"
              :show-all-prices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
            <ArbitrageSubItem
              v-else-if="arbOnlyItem"
              :coingecko-id="coin.coingeckoId"
              :item="arbOnlyItem"
              :type="arbOnlyItem.type"
              :show-all-prices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="arbSellAt"
              :coingecko-id="coin.coingeckoId"
              :item="arbSellAt"
              :type="arbSellAt.type"
              :price-type="arbitrageSettings.priceType"
              :show-all-prices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </div>
        </div>
        <div v-if="shouldShowFundingArbitrageRow" class="d-flex flex-row">
          <div style="flex: 0 0 11.1111%" class="py-1">
            <div><small class="text-secondary">Funding</small></div>
            <div>{{ displayFundingRateArbitragePercent }}%</div>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="fundingArbLongItem"
              :coingecko-id="coin.coingeckoId"
              :item="fundingArbLongItem"
              :type="fundingArbLongItem.type"
              :price-type="arbitrageSettings.priceType"
              :show-all-prices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="fundingArbShortItem"
              :coingecko-id="coin.coingeckoId"
              :item="fundingArbShortItem"
              :type="fundingArbShortItem.type"
              :price-type="arbitrageSettings.priceType"
              :show-all-prices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </div>
        </div>
      </div>
      <b-col cols="1">
        <b-button variant="link" @click="showMore = !showMore">
          {{ showMore ? "less" : "more" }}
        </b-button>
        <div v-if="showPinUnpinActions">
          <b-button v-if="coin.isPinned" size="sm" variant="link" class="text-secondary mr-2" @click="unpin">
            <b-iconstack>
              <b-icon-pin shift-h="-2" scale="0.75"></b-icon-pin>
              <b-icon-slash scale="2" shift-h="1" stacked></b-icon-slash>
            </b-iconstack>
          </b-button>
          <b-button v-else size="sm" variant="link" class="text-secondary mr-2" @click="pin">
            <b-icon-pin-angle></b-icon-pin-angle>
          </b-button>
          <b-button size="sm" variant="link" class="text-secondary" @click="blacklist">
            <b-icon-dash-circle></b-icon-dash-circle>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <template v-if="showMore">
      <b-row v-if="dexItems.length" no-gutters>
        <b-col cols="2"></b-col>
        <b-col cols="1" class="py-1">
          <span class="text-secondary">Chains ({{ dexItems.length }})</span>
        </b-col>
        <b-col cols="8" class="row no-gutters">
          <b-col v-for="item in dexItems" :key="item.chainId" cols="6">
            <ArbitrageSubItem
              :coingecko-id="coin.coingeckoId"
              :item="item"
              type="dex"
              :show-all-prices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="cexItems.length" no-gutters>
        <b-col cols="2"></b-col>
        <b-col cols="1" class="py-1">
          <span class="text-secondary">Exchanges ({{ cexItems.length }})</span>
        </b-col>
        <b-col cols="8" class="row no-gutters">
          <b-col v-for="item in cexItems" :key="item.exchange" cols="6">
            <ArbitrageSubItem
              :coingecko-id="coin.coingeckoId"
              :item="item"
              type="cex"
              :show-all-prices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </b-col>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .img-coin {
    width: 36px;
    height: 36px;
    margin-right: 0.5em;
  }
  .img-exchange {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }
  .bg-highlight-hover:hover {
    background-color: lightyellow;
  }
</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import BaseComponent from "@/components/BaseComponent";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import * as marketDataService from "@/services/marketDataService";
  import ArbitrageSubItem from "@/components/arbitrage/ArbitrageSubItem.vue";

  const volNumberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  @Component({
    components: {ArbitrageSubItem}
  })
  export default class ArbitrageItem extends BaseComponent {

    /*
    {
        "coingeckoId": "constitutiondao",
        "symbol": "people",
        "name": "ConstitutionDAO",
        "logoUrl": null,
        "highestArbitragePercent": 1.486688327962151,
        "cex": [
            {
                "exchange": "binance",
                "asset": "PEOPLE",
                "price": 0.02104036181973889,
                "volume24h": 2124181.585585,
                "networks": [
                    {
                        "network": "BSC",
                        "chainId": 56,
                        "name": "BNB Smart Chain (BEP20)",
                        "isDefault": false,
                        "depositEnabled": true,
                        "withdrawEnabled": true,
                        "withdrawAmountStep": 1e-8,
                        "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
                        "memoRegex": "",
                        "hasMemo": false,
                        "withdrawFee": 3.42,
                        "withdrawMin": 6.84,
                        "withdrawMax": 9999999
                    },
                    {
                        "network": "ETH",
                        "chainId": 1,
                        "name": "Ethereum (ERC20)",
                        "isDefault": true,
                        "depositEnabled": true,
                        "withdrawEnabled": true,
                        "withdrawAmountStep": 1e-8,
                        "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
                        "memoRegex": "",
                        "hasMemo": false,
                        "withdrawFee": 318,
                        "withdrawMin": 636,
                        "withdrawMax": 9999999
                    }
                ],
                "buyHere": false,
                "sellHere": true
            },
            {
                "exchange": "okx",
                "asset": "PEOPLE",
                "price": 0.02103,
                "volume24h": 1484441.079169198,
                "networks": [
                    {
                        "network": "PEOPLE-ERC20",
                        "name": "PEOPLE-ERC20",
                        "isDefault": true,
                        "depositEnabled": true,
                        "withdrawEnabled": true,
                        "withdrawAmountStep": 1e-8,
                        "hasMemo": false,
                        "withdrawFee": 291.24868065,
                        "withdrawMin": 0.1,
                        "withdrawMax": 100000000
                    }
                ],
                "buyHere": false,
                "sellHere": false
            }
        ],
        "dex": [
            {
                "chainId": 1,
                "address": "0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71",
                "name": "ConstitutionDAO",
                "symbol": "people",
                "decimals": 18,
                "price": 0.020732139521338323,
                "priceBuying": 0.02125503370438425,
                "priceSelling": 0.020209245338292384,
                "volume24h": null,
                "liquidity": 30523.41033876764,
                "buyHere": true,
                "sellHere": false
            }
        ]
    }
     */
    @Prop() coin;
    @Prop() arbitrageSettings;
    @Prop({ default: false }) isFocusMode;

    symbol = "";
    displayArbitragePercent = "";
    displayFundingRateArbitragePercent = "";
    backgroundAlpha = "0";

    arbBuyAt = null;
    arbSellAt = null;
    arbOnlyItem = null;

    fundingArbLongItem = null;
    fundingArbShortItem = null;

    cexItems = [];
    dexItems = [];

    showMore = false;
    showPinUnpinActions = true;

    @Watch("coin", { immediate: true, deep: true })
    onCoinChange() {
      this.symbol = this.coin.symbol.toUpperCase();

      const highestArbitragePercentBN = BigNumber(this.coin.highestArbitragePercent || 0);
      this.displayArbitragePercent = highestArbitragePercentBN.toFixed(2);

      if (highestArbitragePercentBN.gte(5)) {
        this.backgroundAlpha = BigNumber.min(highestArbitragePercentBN.dividedBy(50), 1).toFixed(2);
      } else {
        this.backgroundAlpha = "0";
      }

      for (const item of this.coin.cex) {
        item.type = "cex";
      }
      for (const item of this.coin.dex) {
        item.type = "dex";
      }

      this.cexItems = this.coin.cex;
      this.dexItems = this.coin.dex;
      const arr = [...this.coin.cex, ...this.coin.dex];

      if (arr.length >= 2) {
        this.arbBuyAt = arr.find(item => item.buyHere) || arr[0];
        this.arbSellAt = arr.find(item => item.sellHere) || arr[1];
        this.arbOnlyItem = null;
      } else {
        this.arbBuyAt = this.arbSellAt = null;
        this.arbOnlyItem = arr[0] || null;
      }

      const highestFundingRateArbitragePercentBN = BigNumber(this.coin.highestFundingRateArbitragePercent || 0);
      this.displayFundingRateArbitragePercent = highestFundingRateArbitragePercentBN.toFixed(4);
      if (highestFundingRateArbitragePercentBN.gt(0)) {
        this.fundingArbLongItem = this.cexItems.find(item => item.fundingLongHere);
        this.fundingArbShortItem = this.cexItems.find(item => item.fundingShortHere);
      } else {
        this.displayFundingRateArbitragePercent = "";
        this.fundingArbLongItem = this.fundingArbShortItem = null;
      }

      // reset
      this.showPinUnpinActions = !this.isFocusMode;
    }

    get shouldShowPriceArbitrageRow() {
      return this.coin.isPinned ||
        !this.displayFundingRateArbitragePercent ||
        !this.arbitrageSettings.minFundingRateArbitragePercent ||
        (this.coin.highestArbitragePercent || 0) >= this.arbitrageSettings.minArbitragePercent;
    }

    get shouldShowFundingArbitrageRow() {
      return this.displayFundingRateArbitragePercent && this.arbitrageSettings.minFundingRateArbitragePercent;
    }

    mounted() {
      this.showMore = this.isFocusMode;
    }

    @Emit()
    pin() {
      this.showPinUnpinActions = false;
    }

    @Emit()
    unpin() {
      this.showPinUnpinActions = false;
    }

    @Emit()
    blacklist() {
      this.showPinUnpinActions = false;
    }

    @Emit("onClickWithdraw")
    onClickWithdraw(item) {
      // console.log("ArbitrageItem onClickWithdraw", item);
      return item; // pass back to parent
    }

    @Emit("onClickDeposit")
    onClickDeposit(item) {
      // console.log("ArbitrageItem onClickDeposit", item);
      return item; // pass back to parent
    }


  }
</script>
