import axios from "axios";
import _ from "lodash";
import BigNumber from "bignumber.js";
import {
  AddressLookupTableAccount,
  ComputeBudgetInstruction,
  ComputeBudgetProgram, LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  TransactionInstruction, TransactionMessage, VersionedTransaction
} from "@solana/web3.js";
import type { BlockhashWithExpiryBlockHeight } from "@solana/web3.js";
import * as web3Service from "./web3Service";
import * as jitoBundleService from "@/services/jitoBundleService";
import {createCloseAccountInstruction, getAssociatedTokenAddressSync, NATIVE_MINT} from "@solana/spl-token";

const axiosInstance = axios.create({
  timeout: 10000,
});

export async function getQuote(params: any) {
  const ret = (await axiosInstance.get("https://transaction-v1.raydium.io/compute/swap-base-in", { params })).data;
  if (!ret.success) {
    throw new Error(ret.msg);
  }
  return ret;
}

export async function buildTransaction(swapParams, feeParams) {
  swapParams.txVersion = "V0";
  swapParams.computeUnitPriceMicroLamports = "0";

  const { inputMint, outputMint } = swapParams.swapResponse.data;
  const walletPk = new PublicKey(swapParams.wallet);
  if (inputMint === NATIVE_MINT.toString()) {
    swapParams.wrapSol = true;
  } else {
    swapParams.wrapSol = false;
    swapParams.inputAccount = getAssociatedTokenAddressSync(new PublicKey(inputMint), walletPk);
  }
  if (outputMint === NATIVE_MINT.toString()) {
    swapParams.unwrapSol = true;
  } else {
    swapParams.unwrapSol = false;
  }

  const buildTxRes = (await axiosInstance.post("https://transaction-v1.raydium.io/transaction/swap-base-in", swapParams)).data;
  const base64Tx = buildTxRes.data[0].transaction;
  const swapTx = VersionedTransaction.deserialize(Buffer.from(base64Tx, "base64"));

  const accountKeys = [
    ...swapTx.message.addressTableLookups.map(matl => matl.accountKey.toString())
  ];
  const batchRpcRequests = [
    {
      "jsonrpc": "2.0",
      "id": 0,
      "method": "getMultipleAccounts",
      "params": [
        accountKeys,
      ]
    },
    {
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getLatestBlockhash"
    }
  ];

  const batchRpcResponses = (await axios.post(web3Service.solanaWeb3.rpcEndpoint, batchRpcRequests)).data;

  const accountInfos = batchRpcResponses[0].result.value;
  const addressLookupTableAccounts: AddressLookupTableAccount[] = [];
  for (let i = 0; i < accountInfos.length; i++) {
    const accountInfo = accountInfos[i];
    if (accountInfo) {
      addressLookupTableAccounts.push(new AddressLookupTableAccount({
        key: new PublicKey(accountKeys[i]),
        state: AddressLookupTableAccount.deserialize(Buffer.from(accountInfo.data[0], "base64")),
      }));
    }
  }

  const decompiledMessage = TransactionMessage.decompile(swapTx.message, { addressLookupTableAccounts });
  const instructions = decompiledMessage.instructions;

  if (feeParams.feeMode === "normal") {
    const computeUnits = ComputeBudgetInstruction.decodeSetComputeUnitLimit(instructions.find(i =>
      ComputeBudgetProgram.programId.equals(i.programId) &&
      ComputeBudgetInstruction.decodeInstructionType(i) === "SetComputeUnitLimit"
    )).units;
    const maxUnitPriceBN = BigNumber(feeParams.customPriorityFeeSOL)
      .shiftedBy(15) // to microlamports
      .div(computeUnits)
      .decimalPlaces(0, BigNumber.ROUND_DOWN);
    console.log("maxUnitPrice", maxUnitPriceBN.toNumber());

    if (feeParams.feeLevel === "custom") {
      instructions.unshift(ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: maxUnitPriceBN.toNumber()
      }));

    } else if (["medium", "high", "veryHigh"].includes(feeParams.feeLevel)) {
      const priorityLevel = { medium: "Medium", high: "High", veryHigh: "VeryHigh" }[feeParams.feeLevel];
      const unitPrice = (await axios.post(web3Service.solanaWeb3.rpcEndpoint, {
        "jsonrpc": "2.0",
        "id": 1,
        "method": "getPriorityFeeEstimate",
        "params": [
          {
            "transaction": base64Tx,
            "options": {
              transactionEncoding: "base64",
              priorityLevel,
              evaluateEmptySlotAsZero: true,
            }
          }
        ]
      })).data.result.priorityFeeEstimate;
      const finalUnitPrice = BigNumber.min(unitPrice, maxUnitPriceBN).toNumber();
      console.log("unitPrice", unitPrice, "finalUnitPrice", finalUnitPrice);
      instructions.unshift(ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: finalUnitPrice
      }));
    }

  } else if (feeParams.feeMode === "jitoBundle") {
    // set 0 CU price
    instructions.unshift(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 0 }));

    // add tip tx
    const tipIx = jitoBundleService.tipInstruction(
      walletPk,
      feeParams.jitoTipSolBN.multipliedBy(LAMPORTS_PER_SOL).decimalPlaces(0).toNumber()
    );
    instructions.push(tipIx);
  }

  const blockhashWithExpiry: BlockhashWithExpiryBlockHeight = batchRpcResponses[1].result.value;

  return {
    transaction: new VersionedTransaction(new TransactionMessage({
      payerKey: decompiledMessage.payerKey,
      recentBlockhash: blockhashWithExpiry.blockhash,
      instructions
    }).compileToV0Message(addressLookupTableAccounts)),
    lastValidBlockHeight: blockhashWithExpiry.lastValidBlockHeight
  };
}
